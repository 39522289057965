<template>
  <div class="wrap">
    <img src="@/assets/img/earlySettlement.png" alt="logo图片" width="100%">
    <van-cell title="提前结清测算" is-link :to="{path:'earlySettlementCalculation'}" />
    <van-cell title="提前结清申请" is-link :to="{path:'earlySettlementApplication'}" />
  </div>
</template>
<script>
import {getOpenId} from '@/server'
import { Toast} from 'vant';
import {setCookie,getCookie,domainUrl} from '@/lib/utils';
  export default {
  data() {
    return {
      // openId:localStorage.getItem("openId") ||this.$store.state.openId,
      openId:getCookie('openId'),
      code:this.$route.query.code || ''
    };
  },
  mounted(){
    sessionStorage.setItem('key', 1);
    window.addEventListener("popstate", function(e) {  //回调函数中实现需要的功能
      // if(sessionStorage.getItem('key') != 1) {
      //   sessionStorage.setItem('key', 1);
      // }
      // window.history.pushState('forward', null, '#');
      if(sessionStorage.getItem('key') == 1) {
        document.addEventListener('WeixinJSBridgeReady', function(){ WeixinJSBridge.call('closeWindow'); }, false); 
        WeixinJSBridge.call('closeWindow');
        // parent.WeixinJSBridge.call('closeWindow');
      }
    }, false);
  },
  created(){
    //如果有openid
    if(this.openId){
      getOpenId({openid:this.openId}).then(res=>{
        if(res.wx_code=='0'){
          this.$store.commit('bindStatus', res.bind_status);
          localStorage.setItem("bindStatus", res.bind_status);
          localStorage.setItem("accessToken", res.access_token);
          this.$store.commit('accessToken', res.access_token);
          setCookie('openId',res.openid);
          this.$store.commit('openId', res.openid);
          this.$store.commit('cardId', res.card_id);
          localStorage.setItem("cardId", res.card_id);
          if(res.bind_status !=='1'){
            this.$router.push({ path: '/login?redirect=/earlySettlement' });
          }else{
            this.getDetail();
          }
        }else{
            Toast.fail({
              message: res.msg,
              duration:'3000'
            });
          }
      })
    } else{
      if(!this.code) {
        this.$store.commit('bindStatus', '1');
        localStorage.setItem("bindStatus", '1');
        let uiCode = encodeURIComponent(domainUrl+'/earlySettlement');
        window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxe40be82e1238dfe7&redirect_uri='+uiCode+'&response_type=code&scope=snsapi_userinfo&state=STATE&connect_redirect=1#wechat_redirect';         
      }else{//获取code
        getOpenId({percode:this.code}).then(res=>{
          if(res.wx_code=='0'){
            this.$store.commit('bindStatus', res.bind_status);
            localStorage.setItem("bindStatus", res.bind_status);
            this.$store.commit('accessToken', res.access_token);
            localStorage.setItem("accessToken", res.access_token);
            this.$store.commit('openId', res.openid);
            setCookie('openId',res.openid);
            this.$store.commit('cardId', res.card_id);
            localStorage.setItem("cardId", res.card_id);
            if(res.bind_status !=='1'){
              this.$router.push({ path: '/login?redirect=/earlySettlement' });
            }else{
              this.getDetail();
            }
          }else{
            Toast.fail({
              message: res.msg,
              duration:'3000'
            });
          }
        });
      }
    }
  },
  methods: {
    getDetail(){
      this.$store.commit('imgUrlList', []);
      this.$store.commit('backFlag', '');
    }
  },
};
</script>
<style scoped>
.wrap{ 
    background-color: #fff; 
  }
  .custom-title {
    display:block;
    /* vertical-align: middle; */
  }
  .custom-date {
    display:block;
    color: #999999;
    font-size: 12px;
  }
  .custom-status{
    font-size: 11px;
  }
</style>
<style>
/* #app{
  background: #fff !important;
} */
</style>